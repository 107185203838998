import Header from './Header'
import { connect } from 'react-redux'
import { UserThunkDispatch, removeUserData } from 'store/user'
import { ApplicationState } from 'store'
import { DispatchHeaderProps } from './types'

export const mapState = (state: ApplicationState) => {
  return {
    user: state.user
  }
}

export const mapDispatch = (dispatch: UserThunkDispatch): DispatchHeaderProps => ({
  removeUser: () => dispatch(removeUserData())
})

export default connect(mapState, mapDispatch)(Header)
