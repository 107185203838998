import React, { PureComponent } from 'react'
import injectSheet from 'react-jss'
import classNames from 'classnames'
import PTXWhiteLogo from 'img/ptx_white_logo.png'
import imgFaq from 'img/vectores/faq.svg'
import imgFactura from 'img/vectores/factura.svg'
import imgPol from 'img/vectores/pol-viaje.svg'
import telGlobo from 'img/tel-globo.svg'
import telWhatsApp from 'img/ws-globo.svg'
import telCorreoGlobo from 'img/correo-globo.svg'
import { FooterStyle, FooterProps } from './types'
import { Go } from 'shared/components/common'
import { Button, Col, Container } from 'reactstrap'
import imgFaqInstagram from 'img/IG.svg'
import imgFaqfacebook from 'img/facebook.svg'
import imgFaqTw from 'img/tw.svg'
import Row from 'reactstrap/lib/Row'
import './Footer.css'

const styles: FooterStyle = () => ({
  abordoBlanco: {
    width: '90%'
  }
})

React.createElement('div')

interface ISregister {
  showingQuestions: boolean
  showingAbout: boolean
  showingContact: boolean
}

class Footer extends PureComponent<FooterProps, ISregister> {
  state: ISregister = {
    showingQuestions: false,
    showingAbout: false,
    showingContact: false
  }
  render() {
    let { classes } = this.props

    classes = classes || {}

    return (
      <footer>
        <Container fluid={true}>
          <Row>
            <Col sm={3} className="redes text-center">
              <img style={{ width: '150px' }} src={PTXWhiteLogo} className={classNames(classes.abordoBlanco, 'img-responsive')} />
              <br />
              <br />
              <a href="https://www.instagram.com/viaja.abordo/" target="blank">
                <img src={imgFaqInstagram} />
              </a>
              <a href="https://www.facebook.com/viajaABORDO/" target="blank">
                <img src={imgFaqfacebook} />
              </a>
              <a href="https://twitter.com/AbordoAll" target="blank">
                <img src={imgFaqTw} />
              </a>
            </Col>
            <Col sm={2} className="d-none d-sm-block">
              <br />
              <ul>
                <Go to="/nosotros">
                  <h5>Acerca de nosotros</h5>
                </Go>
                <Go to="/cotizarenvio">
                  <li>Paquetería</li>
                </Go>
              </ul>
            </Col>
            <Col sm={2} className="d-none d-sm-block">
              <br />
              <ul>
                <Go to="/contacto">
                  <h5>Contacto</h5>
                </Go>
                <Go to="/destinos">
                  <li>Destinos</li>
                </Go>

                <Go to="/rastreo">
                  <li>Rastrear Paquete</li>
                </Go>
              </ul>
            </Col>
            <Col sm={2} className="d-none d-sm-block">
              <br />
              <ul>
                <Go to="/faq">
                  <li>
                    <img src={imgFaq} /> Preguntas frecuentes
                  </li>
                </Go>
                <Go to="/politicasviaje">
                  <li>
                    <img src={imgPol} /> Políticas de viaje
                  </li>
                </Go>
                <Go to="/solicitarfactura">
                  <li>
                    <img src={imgFactura} /> Tu Factura
                  </li>
                </Go>
              </ul>
            </Col>
            <Col sm={3} className="text-right d-none d-sm-block">
              <a href="https://wa.me/52166866886303" className="link-footer" target="blank">
                <Button className="button-footer" style={{ background: '#27B43E' }}>
                  ¡Haz click para chatear con nosotros!
                </Button>
                <img src={telWhatsApp} />
              </a>
              <br />
              <a href="tel:668-688-6303" className="link-footer">
                <Button className="button-footer" style={{ background: '#737277' }}>
                  (668) 688 6303
                </Button>
                <img src={telGlobo} />
              </a>
              <br />
              <Go to="/contacto" className="link-footer">
                <Button className="button-footer" style={{ background: '#ff0000' }}>
                  contacto@ptxpaqueteria.com
                </Button>
                <img src={telCorreoGlobo} />
              </Go>
            </Col>
            <Col xs={12} className="text-center d-block d-sm-none">
              <br />
              <a href="https://wa.me/52166866886303" className="small-footer" target="blank">
                <Button className="button-footer" style={{ background: '#27B43E' }}>
                  ¡Haz click para chatear con nosotros!
                </Button>
                <img src={telWhatsApp} />
              </a>
              <a href="tel:668-688-6303" className="small-footer">
                <Button className="button-footer" style={{ background: '#737277' }}>
                  (668) 688 6303
                </Button>
                <img src={telGlobo} />
              </a>
              <Go to="/contacto" className="small-footer">
                <Button className="button-footer" style={{ background: '#ff0000' }}>
                  contacto@ptxpaqueteria.com
                </Button>
                <img src={telCorreoGlobo} />
              </Go>
            </Col>
          </Row>
        </Container>
      </footer>
    )
  }
}

export default injectSheet(styles)(Footer)
