import React, { Component } from 'react'
import classNames from 'classnames'
import styles from './Header.module.css'

import loginImg from 'img/vectores/inicio-ses.svg'
import PTXLogo from 'img/ptx_logo.png'

import {
  Navbar,
  NavbarBrand,
  Collapse,
  Nav,
  NavbarToggler,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { HeaderProps, HeaderState } from './types'
import { Go, AButton } from 'shared/components/common'
import { navigate } from 'gatsby'
import { AuthService } from 'auth/services'

React.createElement('div')

class Header extends Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    open: false
  }

  constructor(props: HeaderProps) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }

  closeSesion = () => {
    navigate('/iniciarsesion')
    AuthService.logout()
    this.props.removeUser()
  }
  registerUser = () => {
    navigate('/registrousuario')
  }

  toggle() {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { user } = this.props

    return (
      <header className="sticky-top border-header">
        <Container fluid className="sesion">
          <Row>
            <Col sm={4} md={6} className="d-none d-lg-block" />
            <Col sm={3} md={1} className="d-none d-lg-block" />
            <Col sm={12} md={5} xs={12} className={'text-right'}>
              <span>
                {user ? (
                  <UncontrolledDropdown className="d-inline-block">
                    <DropdownToggle tag="div" caret style={{ cursor: 'pointer' }}>
                      <img src={loginImg} className="img-responsive" />
                      {user.name}
                    </DropdownToggle>
                    <DropdownMenu style={{ zIndex: 99999 }}>
                      <DropdownItem>
                        <Go to="/editarperfil">Editar Perfil</Go>
                      </DropdownItem>
                      <DropdownItem>
                        <Go to="/cambiarcontraseña">Cambiar contraseña</Go>
                      </DropdownItem>
                      {/* <DropdownItem>
                        <Go to="/historialviajes">Ver historial de viajes</Go>
                      </DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : (
                  <Go to="/iniciarsesion">
                    <img src={loginImg} className="img-responsive" />
                    Iniciar sesión
                  </Go>
                )}
              </span>
              <span>{user ? <a onClick={this.closeSesion}>Cerrar Sesión</a> : <a onClick={this.registerUser}>Registro</a>}</span>
            </Col>
          </Row>
        </Container>

        <Navbar className="border-top-navbar" color="light" light expand="lg">
          <NavbarBrand
            tag={() => (
              <Go className="navbar-brand" to="/">
                <img style={{ width: '150px' }} src={PTXLogo} className="img-responsive" />
              </Go>
            )}
          />
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.open} navbar>
            <Nav className="ml-auto" navbar style={{ gap: '15px', alignItems: 'center' }}>
              <Go to="/solicitarfactura" className={styles.navLink}>
                <span className={styles.navItem}>Solicita tu factura</span>
              </Go>
              <Go to="/rastreo" className={styles.navLink}>
                <span className={styles.navItem}>Rastrea tu paquete</span>
              </Go>

              <Go to="/centrales" className={styles.navLink}>
                <span className={styles.navItem}>Nuestras Terminales</span>
              </Go>
              <Go to="/contacto" className={styles.navLink}>
                <span className={styles.navItem}>Contáctanos</span>
              </Go>
              <Go to="/cotizarenvio" className={classNames(styles.navLink, styles.buttonLink)}>
                <span className={styles.navItem}>
                  <AButton variant="pink" className="m-0 nuestras-terminales">
                    Cotiza tu envío
                  </AButton>
                </span>
              </Go>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    )
  }
}

export default Header
