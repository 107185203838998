import es from './locales/es.json'

// Locale data
import esData from 'react-intl/locale-data/es'

const msgs: any = { es }

const data = [...esData]

export { msgs as localeMessages }
export { data as localeData }
